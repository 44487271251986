import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import StaticContent from '@nubank/www-latam-commons/components/StaticComponent/StaticComponent';

import { HeroBlurContainer } from './styles/HeroBlurContainer';
import { StyledTypography } from './styles/Typography';

const NuDisclaimer = ({ message, show }) => {
  const { formatMessage } = useIntl();
  const messageFormat = useMemo(() => (message ? formatMessage({ id: message }) : ''), [message, formatMessage]);

  return show ? (
    <HeroBlurContainer>
      <StaticContent>
        <StyledTypography
          data-testid="Blur text"
          variant="paragraph1"
          dangerouslySetInnerHTML={{ __html: messageFormat }}
        />
      </StaticContent>
    </HeroBlurContainer>
  ) : null;
};

NuDisclaimer.defaultProps = {
  show: false,
};

NuDisclaimer.propTypes = {
  message: PropTypes.string.isRequired,
  show: PropTypes.bool,
};

export default NuDisclaimer;
